<template>
<div id="book-app">
    <NavBar />
    <AppointmentComponent />
    <Testimonials />
    <TheFooter />
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import TheFooter from '@/components/TheFooter.vue';
import AppointmentComponent from '@/components/user/AppointmentComponent.vue';
import Testimonials from '@/components/user/Testimonials.vue';

export default {
	components: {
        NavBar,
		TheFooter,
        Testimonials,
        AppointmentComponent
	}
}
</script>

<style scoped>

</style>